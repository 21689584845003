/* eslint-disable max-len */
import {
    useEffect, useMemo, useRef, useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Barcode from 'react-barcode';

import NoResult from 'components/no-result';
import DraggablePopup from 'components/widgets/draggable-popup/DraggablePopup';
import useQueryTransaction, { QueryTransactionCardItem } from 'hooks/transaction/useQueryTransaction';
import './scanQueryPopup.scss';
import Loader from 'components/loaders/loader';
import { LoaderInButtonFillColor } from 'constants/defaults';
import { isValidNumber, neitherNullNorUndefined } from 'services/validation';
import useToast from 'components/widgets/toast/useToast';
import { WithPagesForwardedActions } from 'components/widgets/draggable-popup/DraggablePopupPages';
import PrimaryButton from 'components/form/button/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import useLocale from 'hooks/locale/useLocale';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { PremiumBackButton } from 'components/premium-screens/back-button/PremiumBackButton';
import QRCodeContainer from 'components/widgets/qr-code-container/QRCodeContainer';

import classNames from 'classnames';
import QueryCardItem from './item/QueryCardItem';

interface Props {
    cards: QueryTransactionCardItem[];
    token: string;
    shouldShow: boolean;
    onClose: () => void;
}

const ScanQueryPopup = ({
    shouldShow, onClose, cards, token,
}: Props) => {
    const intl = useIntl();
    const { triggerToast } = useToast();
    const navigate = useNavigate();
    const lang = useLocale();

    const pagesRef = useRef<WithPagesForwardedActions>();
    const paginateTo = (newPage: number) => pagesRef.current?.paginateTo(newPage);

    const fileInputRef = useRef(null);
    const [document, setDocument] =  useState<File>(null);
    const [borderColor, setBorderColor] = useState('#0E1118');
    const [isLoading, setLoader] = useState(false);
    const [amount, setAmount] = useState('');
    const [selectedCardID, setSelectedCardID] = useState(null);
    const queryTransaction = useQueryTransaction();

    const backToHome = () => {
        navigate(All_PATHS_NAMESPACES[lang].home.path);
    };

    const handleFileSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;

        if (files.length > 0) {
            const [file] = files;

            setDocument(file);
        }
    };

    const onBuy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e?.preventDefault();

        setLoader(true);

        queryTransaction.onBuy(token, selectedCardID)
                .then((result) => {
                    if (!result || (neitherNullNorUndefined(result?.success) && result?.success === false)) {
                        setBorderColor('rgb(186, 50, 50)');
                        paginateTo(3);

                        return;
                    }

                    paginateTo(1);
                })
                .finally(() => setLoader(false));
    };

    const onConfirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        const parseAmount = Number(amount);

        if (!amount || !isValidNumber(parseAmount)) {
            triggerToast(intl.formatMessage({ id: 'scan-query.popup.amount-invalid' }), 'warn');

            return;
        }


        setLoader(true);

        queryTransaction.onRequest(queryTransaction.buyResults.invoiceId, parseAmount, document)
                .then((result) => {
                    if (!result || (neitherNullNorUndefined(result?.success) && result?.success === false)) {
                        setBorderColor('rgb(186, 50, 50)');
                        paginateTo(3);

                        return;
                    }

                    setBorderColor('rgb(50, 186, 124)');
                    paginateTo(2);
                })
                .finally(() => setLoader(false));

        // paginateTo(1);
    };

    useEffect(() => {
        if (token && cards.length) {
            return;
        }

        onBuy(null);
    }, [cards, token]);


    const hasCards = useMemo(() => cards?.length > 0, [cards]);

    const backToForm = () => {
        setBorderColor('#0E1118');
        paginateTo(hasCards ? 0 : 1);
    };

    const code = queryTransaction?.buyResults?.discountCode ?? 'N/A';
    const selectedCard = cards?.find(card => card.id === selectedCardID);
    const discountAmount = useMemo(() => {
        if (!selectedCard) {
            return 0;
        }

        const isCoupon = selectedCard.type === 'COUPON';
        const currency = selectedCard.amount.currency.includes('PERCENT') ? '%' : selectedCard.amount.currency;

        const price = isCoupon
            ? `-${ selectedCard.amount.price }${ currency }`
            : `${ selectedCard.amount.price }${ currency }`;

        return price;
    }, [selectedCard]);

    return (
        <DraggablePopup
            isDraggable={false}
            closeOnBackdropClick={false}
            shouldShowHandle={false}
            noMinHeight
            show={shouldShow}
            onClose={onClose}

            className={classNames('scan-query-popup', { hasCards })}
            wrapperStyle={{
                borderWidth:  1,
                borderStyle:  'solid',
                borderColor,
                overflow:     'hidden',
                maxHeight:    'unset',
                paddingLeft:  0,
                paddingRight: 0,

                // overflow:    'auto',
                // height: 'calc(var(--vh, 1vh) * 100)',

            }}
        >
            <DraggablePopup.PagesContainer
                canSwipeBetweenPages={false}
                ref={pagesRef}
            >
                {/* eslint-disable-next-line */}
                {/* @ts-ignore */}
                {hasCards ? <form key="scan-query-card-form" className="scan-query-card-form">
                    <h2 className="scan-query-padding">{intl.formatMessage({
                        id: 'scan-query.popup.card-title',
                    })}</h2>

                    <p className="scan-query-padding">{intl.formatMessage({
                        id: 'scan-query.popup.card-paragraph',
                    })}</p>

                    <h4 className="scan-query-padding">{intl.formatMessage({
                        id: 'scan-query.popup.card-select',
                    })}</h4>

                    <div className="scan-query-cards-wrapper">
                        {cards?.length ? cards?.map((card) => {
                            const isCoupon = card.type === 'COUPON';
                            const currency = card.amount.currency.includes('PERCENT') ? '%' : card.amount.currency;

                            const price = isCoupon
                                ? `-${ card.amount.price }${ currency }`
                                : `${ card.amount.price }${ currency }`;

                            return (
                                <QueryCardItem
                                    key={card.id}
                                    id={card.id}
                                    title={card.title}
                                    price={price}
                                    onClick={(id) => {
                                        if (id === selectedCardID) {
                                            setSelectedCardID(null);

                                            return;
                                        }

                                        setSelectedCardID(id);
                                    }}
                                    selected={card.id === selectedCardID}
                                />
                            );
                        }) : <NoResult message={intl.formatMessage({ id: 'scan-query.popup.no-cards' })} />}
                    </div>

                    <button
                        type="submit"
                        disabled={isLoading}
                        className="confirm_payment btn-lg-primary"
                        onClick={onBuy}
                    >
                        {isLoading ? (
                            <Loader fill={LoaderInButtonFillColor} />
                        ) : intl.formatMessage({ id: 'scan-query.popup.card-button' })}
                    </button>
                </form> : null}

                <form key="scan-query-amount-form" className={classNames('scan-query-amount-form', { hasCards })} style={{ position: 'relative' }}>
                    <div className="scan-query-amount-title">
                        {hasCards ? (
                            <>
                                <PremiumBackButton onClick={backToForm}/>
                                <h2 className="scan-query-padding">{intl.formatMessage({
                                    id: 'scan-query.popup.amount-title-1',
                                })}</h2>
                            </>
                        ) : (
                            <h2 className="scan-query-padding">{intl.formatMessage({
                                id: 'scan-query.popup.amount-title-2',
                            })}</h2>
                        )}
                    </div>

                    {discountAmount ? <div className="query-amount-price">
                        <h5>{discountAmount}</h5>
                    </div> : null}

                    <div className="scan-query-amount-flow">
                        {!hasCards ? (
                            <div className="scan-query-amount-receipt">
                                <h2>{intl.formatMessage({
                                    id: 'scan-query.popup.document-title',
                                })}</h2>


                                <p>{intl.formatMessage({
                                    id: 'scan-query.popup.document-point-1',
                                })}</p>
                                <p>{intl.formatMessage({
                                    id: 'scan-query.popup.document-point-2',
                                })}</p>
                            </div>
                        ) : null}

                        {discountAmount ? (
                            <>
                                <div style={{ height: 100 }}/>
                                {/* <div className="query-amount-price">
                                    <h5>{discountAmount}</h5>
                                </div> */}

                                <div className="query-amount-codes">
                                    <div className="query-amount-left-codes">
                                        <div className="query-amount-code">
                                            <p>{intl.formatMessage({
                                                id: 'scan-query.popup.bar-code',
                                            })}</p>
                                            <div>
                                                <div className="query-amount-bar-code">
                                                    <Barcode value={code} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="query-amount-code">
                                            <p>{intl.formatMessage({
                                                id: 'scan-query.popup.api-code',
                                            })}</p>
                                            <div>
                                                <p>{code}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="query-amount-code">
                                        <p>{intl.formatMessage({
                                            id: 'scan-query.popup.qr-code',
                                        })}</p>
                                        <QRCodeContainer value={code} />
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <p className="scan-query-amount-label scan-query-padding">{intl.formatMessage({
                            id: 'scan-query.popup.amount-input',
                        })}</p>
                        <div className="input-wrapper">
                            <input
                                type="string"
                                name="amount"
                                className="scan-query-padding"
                                placeholder={intl.formatMessage({
                                    id: 'scan-query.popup.amount-placeholder',
                                })}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>

                        {hasCards ? (
                            <>
                                <div className="scan-query-amount-di bt">
                                    <p>{intl.formatMessage({
                                        id: 'scan-query.popup.voucher-id',
                                    })}</p>
                                    <p>TAOO1234</p>
                                </div>
                                <div className="scan-query-amount-di bb">
                                    <p>{intl.formatMessage({
                                        id: 'scan-query.popup.emission-date',
                                    })}</p>
                                    <p>07 Mai 2023, 11:05</p>
                                </div>
                            </>
                        ) : null}

                        <div className="scan-query-amount-receipt">
                            {hasCards ? (
                                <>
                                    <h2>{intl.formatMessage({
                                        id: 'scan-query.popup.document-title',
                                    })}</h2>


                                    <p>{intl.formatMessage({
                                        id: 'scan-query.popup.document-point-1',
                                    })}</p>
                                    <p>{intl.formatMessage({
                                        id: 'scan-query.popup.document-point-2',
                                    })}</p>
                                </>
                            ) : null}


                            <p className="scan-query-amount-file-label scan-query-padding">{intl.formatMessage({
                                id: 'scan-query.popup.document-input',
                            })}</p>
                            <div onClick={() => fileInputRef.current?.click()} className="input-wrapper file-input-wrapper">
                                <p>{document?.name ?? intl.formatMessage({
                                    id: 'scan-query.popup.document-placeholder',
                                })}</p>
                                <input
                                    ref={fileInputRef}
                                    className="file-input"
                                    name="document"
                                    type="file"
                                    onChange={handleFileSelectChange}
                                />
                            </div>
                        </div>
                    </div>


                    <button
                        type="submit"
                        disabled={isLoading}
                        className="confirm_payment btn-lg-primary"
                        onClick={onConfirm}
                    >
                        {isLoading ? (
                            <Loader fill={LoaderInButtonFillColor} />
                        ) : intl.formatMessage({ id: 'scan-query.popup.amount-button' })}
                    </button>
                </form>

                <div key="scan-query-success" className="scan-query-success">
                    <h2><FormattedMessage id="scan-query.popup.success.title"/></h2>
                    <p><FormattedMessage id="scan-query.popup.success.description"/></p>

                    <PrimaryButton className="scan-query-success-btn" isSuccess onClick={backToHome}>
                        <FormattedMessage id="scan-query.popup.success.button"/>
                    </PrimaryButton>
                </div>

                <div key="scan-query-failure" className="scan-query-failure">
                    <PremiumBackButton onClick={backToForm}/>
                    <h2><FormattedMessage id="scan-query.popup.failed.title"/></h2>
                    <p><FormattedMessage id="scan-query.popup.failed.description"/></p>

                    <PrimaryButton className="scan-query-failure-btn" isDanger onClick={backToForm}>
                        <FormattedMessage id="scan-query.popup.failed.button"/>
                    </PrimaryButton>
                </div>

            </DraggablePopup.PagesContainer>
        </DraggablePopup>
    );
};

export default ScanQueryPopup;
